import React from 'react';
import { Link } from 'react-router-dom'; // Import Link component
import { Container, Row, Col } from 'react-bootstrap'; // Import React-Bootstrap components
// import './HomePage.css'; // Import custom CSS for styling

function HomePage() {
    return (
      <div className="home-page">
        <header className="hero-section">
          <Container>
            <Row className="text-center py-3 my-3">
              <Col lg={12} className="align-items-center text-center">
                <span className="fw-bold" style={{ fontSize: "4rem", letterSpacing: "-0.0275em" }}><span className="text-success uppercase">Would</span> <span className="text-warning uppercase">You</span> <span className="text-info uppercase">Kindly</span></span>
                <h3 className="hero-title text-center">
                    Task and Contact Management Made Effortless
                </h3>
              </Col>
              <Col lg={12} className="align-items-center text-center">
                <p className="hero-subtitle text-center fs-4">
                    Stay on top of your tasks and contacts with ease. <Link to="/tasks" className="btn btn-primary btn-sm shadow-sm">Get Started Now</Link>
                </p>
              </Col>
              <Col lg={12} className="d-flex align-items-center d-none">
                {/* <div className="col-lg-12 hero-image">
                <img src="path/to/image.png" alt="Productivity illustration" />
                </div> */}
              </Col>
            </Row>
          </Container>
        </header>
  
        <section className="features-section py-5">
          <Container>
            <Row className="d-flex align-items-center">
              {/* Feature items */}
              <Col md={5} className="feature-item text-center py-3">
                <i className="fas fa-tasks feature-icon d-none"></i>
                <h3 className="feature-title">Unified Task Management</h3>
                <p className="feature-description">
                    Streamline your day with our dynamic task manager. Visualize your workload, set deadlines, and conquer your to-do list like never before!
                </p>
              </Col>
              <Col md={7} className="feature-item py-3 text-center">
                <img src="/assets/list.png" style={{ maxHeight: "320px", width: "auto", maxWidth: "100%"  }} alt="flat illustration of person with task list"/>
              </Col>
            </Row>
            <Row className="d-flex align-items-center">
              {/* Repeat for other feature items */}
              <Col md={7} className="feature-item py-3 text-center">
                <img src="/assets/contact.png" style={{ maxHeight: "320px", width: "auto", maxWidth: "100%" }} alt="flat illustration of person managing contacts"/>
              </Col>
              <Col md={5} className="feature-item text-center py-3">
                <i className="fas fa-address-book feature-icon d-none"></i>
                <h3 className="feature-title">Seamless Contact Management</h3>
                <p className="feature-description">
                    Nurture your connections by efficiently managing contacts. Store detailed information and categorize them in a way that suits your network.
                </p>
              </Col>
            </Row>
            </Container>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials-section py-5 bg-success bg-muted border-top-0 border-left-0 border-right-0 border-info border-bottom border-5">
        <Container>
            {/* ... */}
            <h2 className="text-center mb-4">What are others saying?</h2>
            <Row>
                <Col md={4} className="feature-item py-3 text-center">
                    <blockquote className="testimonial text-light">
                    "This tool has revolutionized the way I organize my daily tasks and contacts. It's intuitive and user-friendly!"
                    <footer className="testimonial-author mt-3 text-dark">- Jane Doe, Freelance Writer</footer>
                    </blockquote>
                </Col>
                <Col md={4} className="feature-item py-3 text-center">
                    <blockquote className="testimonial text-light">
                    "The Pomodoro timer feature is a game-changer for productivity. Highly recommend this for anyone looking to stay focused."
                    <footer className="testimonial-author mt-3 text-dark">- John Smith, Software Developer</footer>
                    </blockquote>
                </Col>
                <Col md={4} className="feature-item py-3 text-center">
                    <blockquote className="testimonial text-light">
                    "I love how I can customize the interface to match my preferences. The QR code sharing feature is also incredibly convenient."
                    <footer className="testimonial-author mt-3 text-dark">- Sarah Lee, Project Manager</footer>
                    </blockquote>
                </Col>
                <Col md={12} className="feature-item py-3 text-center">
                    <div className="text-center my-3">
                        <Link to="/tasks" className="btn btn-warning shadow-sm">Start Seeings Results Now - for Free!</Link>
                    </div>
                </Col>
            </Row>
        </Container>
        </section>

        <section className="features-section py-5">
          <Container>
            <Row className="d-flex align-items-center">

              <Col md={5} className="feature-item text-center py-3">
                <i className="fas fa-stopwatch feature-icon d-none"></i>
                <h3 className="feature-title">Focus with Pomodoro Timer</h3>
                <p className="feature-description">
                    Supercharge your productivity! Our integrated Pomodoro timer helps you stay focused and make the most of your work sessions.
                </p>
              </Col>
              <Col md={7} className="feature-item py-3 text-center">
                <img src="/assets/time.png" style={{ maxHeight: "320px", width: "auto", maxWidth: "100%" }} alt="flat illustration of person enjoying free time"/>
              </Col>

              <Col md={7} className="feature-item py-3 text-center">
                <img src="/assets/share.png" style={{ maxHeight: "320px", width: "auto", maxWidth: "100%" }} alt="flat illustration of person sharing information"/>
              </Col>
              <Col md={5} className="feature-item text-center py-3">
                <i className="fas fa-qrcode feature-icon d-none"></i>
                <h3 className="feature-title">Effortless Data Sharing</h3>
                <p className="feature-description">
                    Simplify collaboration with our QR code feature. Share tasks instantly and sync up with your team or friends in a flash.
                </p>
              </Col>

              </Row>
            </Container>
        </section>

        {/* Why Choose Us Section */}
        <section className="why-choose-us-section py-5 bg-info text-light border-top-0 border-left-0 border-right-0 border-warning border-bottom border-5">
          <Container>
            {/* ... */}
            <h2 className="text-center mb-4">Why Choose Our Solution?</h2>
            <Row>
                <Col md={4} className="feature-item py-3">
                    <ul className="why-choose-us-list">
                        <li><strong>User-Friendly Design:</strong><br />Easy to navigate interface designed for efficiency.</li>
                    </ul>
                </Col>
                <Col md={4} className="feature-item py-3">
                    <ul className="why-choose-us-list">
                        <li><strong>Advanced Security:</strong><br />Your data is encrypted and securely stored.</li>
                    </ul>
                </Col>
                <Col md={4} className="feature-item py-3">
                    <ul className="why-choose-us-list">
                        <li><strong>Customizable Experience:</strong><br />Tailor the application to fit your unique needs.</li>
                    </ul>
                </Col>
                <Col md={4} className="feature-item py-3">
                    <ul className="why-choose-us-list">
                        <li><strong>Integrated Productivity Tools:</strong><br />Pomodoro timer and QR code sharing to enhance your productivity.</li>
                    </ul>
                </Col>
                <Col md={4} className="feature-item py-3">
                    <ul className="why-choose-us-list">
                        <li><strong>Seamless Synchronization:</strong><br />Keep your tasks and contacts synced across devices.</li>
                    </ul>
                </Col>
                <Col md={4} className="feature-item py-3">
                    <ul className="why-choose-us-list">
                        <li><strong>Exceptional Support:</strong><br />Dedicated support team to ensure a smooth experience.</li>
                    </ul>
                </Col>
                <Col md={12} className="feature-item py-3 text-center">
                    <div className="text-center my-3">
                        <Link to="/tasks" className="btn btn-success shadow-sm">Get Started Today for Free!</Link>
                    </div>
                </Col>
            </Row>
          </Container>
        </section>

        <section className="features-section py-5">
          <Container>
            <Row className="d-flex align-items-center">

              <Col md={5} className="feature-item text-center py-3">
                <i className="fas fa-lock feature-icon d-none"></i>
                <h3 className="feature-title">Secure Data Storage</h3>
                <p className="feature-description">
                    Rest easy knowing your data is protected. We use state-of-the-art encryption to keep your tasks and contacts secure.
                </p>
              </Col>
              <Col md={7} className="feature-item py-3 text-center">
                <img src="/assets/secure.png" style={{ maxHeight: "320px", width: "auto", maxWidth: "100%" }} alt="flat illustration of person putting information into a vault"/>
              </Col>

              <Col md={7} className="feature-item py-3 text-center">
                <img src="/assets/personal.png" style={{ maxHeight: "320px", width: "auto", maxWidth: "100%" }} alt="flat illustration of person with message bubbles around them"/>
              </Col>
              <Col md={5} className="feature-item text-center py-3">
                <i className="fas fa-user-cog feature-icon d-none"></i>
                <h3 className="feature-title">Customizable Experience</h3>
                <p className="feature-description">
                    Make it yours! Customize your task list appearance and functionality to create a space that feels just right for you.
                </p>
              </Col>
              
            </Row>
          </Container>
        </section>
  
        
  
        {/* Final Call to Action Section */}
        <section className="final-cta py-5 bg-warning text-dark">
          <Container className="text-center">
            {/* ... */}
            <Row>
                <Col md={12} className="feature-item py-3 text-center">
                    <section className="final-cta py-5 text-dark">
                        <div className="container text-center">
                            <h2>Ready to Take Control of Your Day?</h2>
                            <p className="text-light fw-bold">Join our community of productive users.</p>
                            <Link to="/tasks" className="btn btn-primary btn shadow-sm">Get Started Now!</Link>
                        </div>
                    </section>
                </Col>
            </Row>
          </Container>
        </section>
  
        {/* Footer Section */}
        <footer className="footer-section">
          {/* ... */}
        </footer>
  
        <div className="background-animation">
          {/* Background animation */}
        </div>

        <div id="installBanner" className="install-banner" style={{ display: 'none' }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-auto">
                <img src="/logo192.png" alt="App Icon" className="app-icon"/>
              </div>
              <div className="col">
                <h4>W Y K</h4>
                <p>Your task and contact manager. Keep everything organized in one place.</p>
              </div>
              <div className="col-auto">
                <button className="btn btn-primary" id="installButton">Install</button>
              </div>
              <div className="col-auto">
                <button className="close-button" onClick={() => document.getElementById('installBanner').style.display = 'none'}>&times;</button>
              </div>
            </div>
          </div>
        </div>

      </div>

      
    );
  }
  
  export default HomePage;
  