

import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { AuthProvider } from "../contexts/FirebaseAuth"
import { FlashProvider } from "../contexts/FlashContext"
// import ProtectedRoute from "./ProtectedRoute"

import FlashContainer from "./FlashContainer"
import TopNav from "./TopNav"

// import Home from "./Home"
// import Products from "./Products"
// import About from "./About"
// import Login from "./GoogleLogin"
// import UserProfile from "./Profile"
import AlertMe from "./AlertMe" // example page for seeing different alert styling options
import FlashForm from "./FlashForm" // example page for testing and debugging the flash

import Tasks from "./Tasks"
// import TasksV2 from "./Tasks-v2"
import Jargon from "./Jargon"
import Footer from "./Footer"
import HomeWYK from "./HomeNew"
import FAQ from "./FAQ"
import NotFoundComponent from './404notfound' // experimental custom 404 page
import './PWA.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import './CustomStyles.css'
import './LUXbootstrap.min.css'

const GA_TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID
const GA_DEBUG_MODE = (process.env.REACT_APP_GA_DEBUG_MODE === "true")
ReactGA.initialize(GA_TRACKER_ID, {debug: GA_DEBUG_MODE})



export default function App() {
    ReactGA.pageview(window.location.href);

    useEffect(() => {
        const updateOnlineStatus = () => {
            if (navigator.onLine) {
                // User is online, hide offline banner or notify user
                console.log("Online");
            } else {
                // User is offline, show offline banner or notify user
                console.log("Offline");
            }
        };

        window.addEventListener('online',  updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online',  updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    return (
        <AuthProvider>
        <FlashProvider>
            <div className="App">
                <TopNav/>

                <Container fluid className="px-0 py-0">
                    <FlashContainer/>

                    <Router>
                        <Switch>
                            <Route exact path="/" component={HomeWYK} />
                            <Route exact path="/tasks" component={Tasks} />
                            
                            <Route exact path="/jargon" component={Jargon} />
                            <Route exact path="/faq" component={FAQ} />
                            

                            {/* <Route exact path="/tasks-testing" component={TasksV2} />
                            <Route exact path="/about" component={About} /> 
                            <Route exact path="/products" component={Products} />
                            <Route exact path="/login" component={Login} />
                            <ProtectedRoute exact path="/profile" component={UserProfile} />
                            <Route exact path="/landing-test" component={HomeWYK} /> */}

                            <Route path="/alerts" component={AlertMe} />
                            <Route path="/flashes" component={FlashForm} />
                            <Route path="*" component={NotFoundComponent} />
                        </Switch>
                    </Router>
                </Container>

                <Footer/>
            </div>
        </FlashProvider>
        </AuthProvider>
    )
}
