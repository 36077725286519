let deferredPrompt;
const installBanner = document.getElementById('installBanner'); // Get the banner
const installButton = document.getElementById('installButton'); // Get the button inside the banner

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  deferredPrompt = e;
  // Update UI notify the user they can install the PWA
  installButton.style.display = 'block';

  installButton.addEventListener('click', (e) => {
    // Hide the whole banner
    installBanner.style.display = 'none';
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      deferredPrompt = null;
    });
  });
});

window.addEventListener('appinstalled', (evt) => {
  // Log the installation of the app
  console.log('Application was installed.');
});
