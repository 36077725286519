// About Us Component
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AboutUs = () => (
    <Container className="my-5">
        <Row>
        <Col md={12}>
                <h2 className="text-center text-info">About Us</h2>
                <p className="text-dark">
                    Welcome to "Would You Kindly", a comprehensive tool designed to streamline your task and contact management needs. Our platform integrates task creation, contact organization, productivity timers, and innovative data sharing features, including QR code scanning, to provide a seamless user experience.
                </p>
                <p className="text-dark">
                    Since our inception in 2023, we have focused on creating a user-friendly interface that accommodates both personal and professional use. Our application is perfect for individuals seeking to enhance their daily productivity or teams looking for an efficient way to collaborate on tasks and contacts.
                </p>
                <p className="text-dark">
                    We're committed to continually evolving our platform to meet the dynamic needs of our users, ensuring data security, and enhancing user engagement through customizable and intuitive design.
                </p>
            </Col>
        </Row>
    </Container>
);

export default AboutUs;