import React, { useState, useEffect, useRef } from 'react';
import CryptoJS from 'crypto-js';
import QRCode from 'qrcode.react';
import { QrReader } from 'react-qr-reader';
import { v4 as uuidv4 } from 'uuid';
import { useFlashUpdate } from "../contexts/FlashContext"
import { Container, Row, Col, Form, Button, Accordion, Card, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const App = () => {
    const [tasks, setTasks] = useState([]);
    const [newTask, setNewTask] = useState('');
    const [showScanner, setShowScanner] = useState(false);
    const [scanResult, setScanResult] = useState(null);
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);
    const [showListNameModal, setShowListNameModal] = useState(false);
    const [listName, setListName] = useState('');
    const [videoDevices, setVideoDevices] = useState([]);
    const [selectedCameraId, setSelectedCameraId] = useState(null);
    const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
    const [contacts, setContacts] = useState([]);
    const [newContactFirstName, setNewContactFirstName] = useState('');
    const [newContactLastName, setNewContactLastName] = useState('');
    const [newContactCompany, setNewContactCompany] = useState('');
    // const [selectedContactForTask, setSelectedContactForTask] = useState('');
    const [selectedTaskForTimer, setSelectedTaskForTimer] = useState('');
    const [taskTimers, setTaskTimers] = useState({}); // {taskId: [interval, interval, ...]}
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    // const qrRef = useRef(null);
    const flash = useFlashUpdate()
    // const secretKey = '29a49287ca738e669ca5a6f68e1a2f070f0ede2ab4007fcecd9e508c43f46d15a50f2cb95734e7a197d7390c20b3c298a959d2fd4711f8ed835649e5829d9dbe';
    const secretKey = 'process.env.REACT_APP_SECRET_TASK_KEY'

    useEffect(() => {
        // Load list name and tasks from local storage
        const storedListName = localStorage.getItem('listName');
        if (storedListName) {
            setListName(storedListName);
        } else {
            // Prompt for a new list name
            const newListName = ""; //prompt("Please set a name for your task list (up to 64 alphanumeric characters):");
            if (newListName && newListName.length <= 64) {
                localStorage.setItem('listName', newListName);
                setListName(newListName);
            }
        }

        const encryptedTasks = localStorage.getItem('tasks');
        if (encryptedTasks) {
            const bytes = CryptoJS.AES.decrypt(encryptedTasks, secretKey);
            const decryptedTasks = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            setTasks(decryptedTasks);
        }

        // Load contacts from local storage
        const encryptedContacts = localStorage.getItem('contacts');
        if (encryptedContacts) {
            const bytes = CryptoJS.AES.decrypt(encryptedContacts, secretKey);
            const decryptedContacts = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            setContacts(decryptedContacts);
        }

        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoInputs = devices.filter(device => device.kind === 'videoinput');
                setVideoDevices(videoInputs);
                if (videoInputs.length > 0) {
                    setSelectedCameraId(videoInputs[0].deviceId); // Default to the first camera
                }
            })
            .catch(err => console.error('Error listing devices: ', err));

        setIsPageLoaded(true);
    }, []);

    useEffect(() => {
        if (isPageLoaded && !listName) {
            // Prompt for a new list name after the page has loaded and if no list name exists
            const newListName = prompt("Please set a name for your task list (up to 64 alphanumeric characters):");
            if (newListName && newListName.length <= 64) {
                localStorage.setItem('listName', newListName);
                setListName(newListName);
            }
        }
    }, [isPageLoaded]); // This effect runs when isPageLoaded changes

    const switchCamera = () => {
        if (videoDevices.length > 0) {
            const nextCameraIndex = (currentCameraIndex + 1) % videoDevices.length;
            setCurrentCameraIndex(nextCameraIndex);
            setSelectedCameraId(videoDevices[nextCameraIndex].deviceId);
        }
    };

    const saveTasksToLocalStorage = (updatedTasks) => {
        const encryptedTasks = CryptoJS.AES.encrypt(JSON.stringify(updatedTasks), secretKey).toString();
        localStorage.setItem('tasks', encryptedTasks);
    };

    // Updated addTask function with event parameter
    const addTask = (event) => {
        event.preventDefault();
        const newTaskObject = {
            id: uuidv4(),
            title: newTask,
            details: '',
            location: '', // new field
            dueDate: '', // new field
            price: 0, // new field
            quantity: 0, // new field
            completed: false,
            createdAt: new Date().toLocaleString(),
            completedAt: '',
            associatedContacts: []
        };
        const updatedTasks = [...tasks, newTaskObject];
        setTasks(updatedTasks);
        saveTasksToLocalStorage(updatedTasks);
        setNewTask('');
        flash({message:"Task created successfully. Have a nice day!", variant: "info"})
    };
    
    const updateTaskDetails = (id, key, value) => {
        const updatedTasks = tasks.map(task =>
            task.id === id ? { ...task, [key]: value } : task
        );
        setTasks(updatedTasks);
        saveTasksToLocalStorage(updatedTasks);
    };

    const toggleTaskCompletion = (id) => {
        const updatedTasks = tasks.map(task =>
            task.id === id ? { ...task, completed: !task.completed, completedAt: !task.completed ? new Date().toLocaleString() : '' } : task
        );
        setTasks(updatedTasks);
        saveTasksToLocalStorage(updatedTasks);
        flash({message:"Task completed! You go Glen Coco!", variant: "success"})
    };

    const deleteTask = (id) => {
        const updatedTasks = tasks.filter(task => task.id !== id);
        setTasks(updatedTasks);
        saveTasksToLocalStorage(updatedTasks);
        // Also, remove associations with this task in all contacts
        const updatedContacts = contacts.map(contact => {
            return { ...contact, associatedTasks: contact.associatedTasks.filter(taskId => taskId !== id) };
        });
        setContacts(updatedContacts);
        saveContactsToLocalStorage(updatedContacts);
        flash({message:"Task deleted successfully. What was I saying?", variant: "danger"})
    };

    const clearLocalStorage = () => {
        // Show a confirmation dialog
        const confirmClear = window.confirm("Are you sure you want to clear all data from local storage? This action cannot be undone.");
        if (confirmClear) {
            localStorage.removeItem('listName');
            localStorage.removeItem('tasks');
            localStorage.removeItem('contacts');
            // Reset state after clearing local storage
            setListName('');
            setTasks([]);
            setContacts([]);
            // Optionally, close the modal or provide feedback
            setShowListNameModal(false);
            // alert('Local storage cleared successfully.');
            flash({message:"Local Storage cleared successfully. Nothing like a clean slate.", variant: "danger"})
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;
        const sourceList = result.source.droppableId;
        const destList = result.destination.droppableId;

        const updatedTasks = Array.from(tasks);
        const [reorderedItem] = updatedTasks.splice(sourceIndex, 1);

        if (sourceList === "activeTasks" || sourceList === "completedTasks") {
            updatedTasks.splice(destIndex, 0, reorderedItem);
        } else if (sourceList === "coldContacts" || sourceList === "hotContacts") {
            const updatedContacts = Array.from(contacts);
            const [reorderedContact] = updatedContacts.splice(sourceIndex, 1);
    
            if (sourceList === destList) {
                updatedContacts.splice(destIndex, 0, reorderedContact);
            } else {
                reorderedContact.hot = destList === "hotContacts";
                updatedContacts.splice(destIndex, 0, reorderedContact);
            }
    
            setContacts(updatedContacts);
            saveContactsToLocalStorage(updatedContacts);
        }
    };

    const encodedTasks = () => {
        const dataToEncode = { listName, tasks };
        return encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(dataToEncode), secretKey).toString());
    };

    const handleResult = (result, error) => {
        if (!!error) {
            console.error(error);
            // alert('Error scanning QR code.');
            return;
        }

        if (result) {
            try {
                const decryptedData = CryptoJS.AES.decrypt(result?.text, secretKey).toString(CryptoJS.enc.Utf8);
                const parsedData = JSON.parse(decryptedData);

                if (Array.isArray(parsedData.tasks) && parsedData.listName === listName) {
                    setScanResult(parsedData.tasks);
                    setShowScanner(false); // Close the scanner modal
                    // Optionally, open the import confirmation modal here
                } else {
                    throw new Error('Invalid format or incorrect list name');
                }
            } catch (error) {
                alert('Invalid QR code format or incorrect list name.');
            }
        }
    };

    const handleError = err => {
        console.error(err);
        // alert('Error scanning QR code.');
    };

    const handleTimerComplete = (taskId, endTime) => {
        // Record the end time of the Pomodoro session
        const updatedTimers = { ...taskTimers };
        if (!updatedTimers[taskId]) {
            updatedTimers[taskId] = [];
        }
        const newInterval = { start: new Date(), end: endTime };
        updatedTimers[taskId].push(newInterval);
        setTaskTimers(updatedTimers);
    
        // Update the task with the new timer interval
        const updatedTasks = tasks.map(task => {
            if (task.id === taskId) {
                return { ...task, pomodoroSessions: [...(task.pomodoroSessions || []), newInterval] };
            }
            return task;
        });
        setTasks(updatedTasks);
        saveTasksToLocalStorage(updatedTasks);
    };

    const handleSelectTaskForTimer = (event) => {
        setSelectedTaskForTimer(event.target.value);
    };

    const importTasks = () => {
        if (scanResult) {
            const newTasks = [...tasks];
            scanResult.forEach(task => {
                if (!newTasks.find(t => t.id === task.id)) {
                    newTasks.push(task);
                }
            });
            setTasks(newTasks);
            setScanResult(null);
            setShowScanner(false);
        }
    };

    const handleListNameChange = (e) => {
        const newName = e.target.value;
        if (newName.length <= 64) {
            setListName(newName);
            localStorage.setItem('listName', newName);
        } else {
            alert('List name must be up to 64 alphanumeric characters.');
        }
    };

    const addContact = (event) => {
        event.preventDefault();
        const newContactObject = {
            id: uuidv4(),
            firstName: newContactFirstName,
            lastName: newContactLastName,
            company: newContactCompany,
            title: '',     // vCard field
            phone: '',     // vCard field
            email: '',     // vCard field
            address: '',   // vCard field
            notes: '',     // vCard field
            datetime: '',  // vCard field
            dateA1: '', // Custom Date Field
            dateB2: '', // Custom Date Field
            hot: true, // Assuming 'hot' is a boolean indicating if the contact is in the 'Hot' category
            createdAt: new Date().toLocaleString(),
            associatedTasks: []
        };
        const updatedContacts = [...contacts, newContactObject];
        setContacts(updatedContacts);
        saveContactsToLocalStorage(updatedContacts);
        // Save to local storage or other persistent storage

        // Reset input fields after adding a contact
        setNewContactFirstName('');
        setNewContactLastName('');
        setNewContactCompany('');
        flash({message:"New Contact created successfully! Someone is popular..", variant: "success"})
    };

    const updateContactDetails = (id, key, value) => {
        const updatedContacts = contacts.map(contact => {
            if (contact.id === id) {
                return { ...contact, [key]: value };
            }
            return contact;
        });
        setContacts(updatedContacts);
        saveContactsToLocalStorage(updatedContacts);
        // Save to local storage or other persistent storage
    };

    const toggleContactCategory = (id) => {
        const updatedContacts = contacts.map(contact =>
            contact.id === id ? { ...contact, hot: !contact.hot } : contact
        );
        setContacts(updatedContacts);
        saveContactsToLocalStorage(updatedContacts);
        // Save to local storage or other persistent storage
        flash({message:"Contact Temperature changed! Did someone leave a window open?", variant: "success"})
    };

    const deleteContact = (id) => {
        const updatedContacts = contacts.filter(contact => contact.id !== id);
        setContacts(updatedContacts);
        saveContactsToLocalStorage(updatedContacts);
        // Also, remove associations with this contact in all tasks
        const updatedTasks = tasks.map(task => {
            return { ...task, associatedContacts: task.associatedContacts.filter(contactId => contactId !== id) };
        });
        setTasks(updatedTasks);
        saveTasksToLocalStorage(updatedTasks);
        flash({message:"Contact deleted. You win some, you lose some.", variant: "danger"})
    };

    const saveContactsToLocalStorage = (updatedContacts) => {
        const encryptedContacts = CryptoJS.AES.encrypt(JSON.stringify(updatedContacts), secretKey).toString();
        localStorage.setItem('contacts', encryptedContacts);
    };
    
    const associateTaskWithContact = (taskId, contactId, resetDropdown) => {
        const updatedTasks = tasks.map(task => {
            if (task.id === taskId && !task.associatedContacts.includes(contactId)) {
                return { ...task, associatedContacts: [...task.associatedContacts, contactId] };
            }
            return task;
        });
    
        const updatedContacts = contacts.map(contact => {
            if (contact.id === contactId && !contact.associatedTasks.includes(taskId)) {
                return { ...contact, associatedTasks: [...contact.associatedTasks, taskId] };
            }
            return contact;
        });
    
        setTasks(updatedTasks);
        setContacts(updatedContacts);
        saveTasksToLocalStorage(updatedTasks);
        saveContactsToLocalStorage(updatedContacts);
    
        // Reset the dropdown for association
        if (resetDropdown) resetDropdown();
        flash({message:"Contact association completed. How many degress left to Kevin Bacon?", variant: "warning"})
    };
    
    const removeAssociation = (taskId, contactId) => {
        const updatedTasks = tasks.map(task => {
            if (task.id === taskId) {
                return { ...task, associatedContacts: task.associatedContacts.filter(id => id !== contactId) };
            }
            flash({message:"Contact association removed. Who's on Second?", variant: "danger"})
            return task;
        });
    
        const updatedContacts = contacts.map(contact => {
            if (contact.id === contactId) {
                return { ...contact, associatedTasks: contact.associatedTasks.filter(id => id !== taskId) };
            }
            return contact;
        });
    
        setTasks(updatedTasks);
        setContacts(updatedContacts);
        saveTasksToLocalStorage(updatedTasks);
        saveContactsToLocalStorage(updatedContacts);
    };

    const formatElapsedTime = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const elapsedMilliseconds = endDate - startDate;
        
        const seconds = Math.floor((elapsedMilliseconds / 1000) % 60);
        const minutes = Math.floor((elapsedMilliseconds / (1000 * 60)) % 60);
        const hours = Math.floor((elapsedMilliseconds / (1000 * 60 * 60)) % 24);
    
        const hoursDisplay = hours > 0 ? `${hours}h ` : '';
        const minutesDisplay = minutes > 0 ? `${minutes}m ` : '';
        const secondsDisplay = seconds > 0 ? `${seconds}s` : '';
    
        return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`;
    };

    const taskList = (filterCompleted, droppableId) => (
        <Droppable droppableId={droppableId}>
            {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Accordion>
                        {tasks.filter(task => task.completed === filterCompleted)
                            .map((task, index) => (
                                <Draggable key={task.id} draggableId={task.id} index={index}>
                                    {(provided) => (
                                        <Card ref={provided.innerRef} {...provided.draggableProps}>
                                            <div {...provided.dragHandleProps} className="drag-handle">
                                                <span className="mr-2 cursor-pointer"><span style={{ paddingLeft: "0.5em" }}>☰</span> <small style={{ float: "right", paddingRight: "0.5em" }}>Created at: {task.createdAt}</small></span>
                                            </div>
                                            <Accordion.Toggle as={Card.Header} eventKey={String(task.id)}>
                                                {task.title}
                                                <div className="float-right">
                                                    <Button variant="link" size="sm" onClick={() => toggleTaskCompletion(task.id)}>
                                                        {task.completed ? 'Reactivate' : 'Complete'}
                                                    </Button>
                                                    {task.completed && (
                                                        <Button variant="link" size="sm" onClick={() => deleteTask(task.id)}>
                                                            Delete
                                                        </Button>
                                                    )}
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={String(task.id)}>
                                                <Card.Body>
                                                    <InputGroup>
                                                        <Container>
                                                            <Row className="py-2">
                                                                <Col col={12}>
                                                                    <small>Description</small>
                                                                    <FormControl
                                                                        as="textarea"
                                                                        value={task.details}
                                                                        onChange={(e) => updateTaskDetails(task.id, 'details', e.target.value)}
                                                                        placeholder="Add details"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="py-2">
                                                                <Col xs={12}>
                                                                    <small>Location / Address</small>
                                                                    <FormControl
                                                                        type="text"
                                                                        value={task.location}
                                                                        onChange={(e) => updateTaskDetails(task.id, 'location', e.target.value)}
                                                                        placeholder="Location/Address"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="py-2">
                                                                <Col xs={12} md={6}>
                                                                    <small>Due By</small>
                                                                    <FormControl
                                                                        type="datetime-local"
                                                                        value={task.dueDate}
                                                                        onChange={(e) => updateTaskDetails(task.id, 'dueDate', e.target.value)}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} md={3}>
                                                                    <small>Price</small>
                                                                    <InputGroup className="mb-3">
                                                                        <InputGroup.Text>$</InputGroup.Text>
                                                                        <FormControl
                                                                            type="number"
                                                                            value={task.price}
                                                                            onChange={(e) => updateTaskDetails(task.id, 'price', e.target.value)}
                                                                            placeholder="0.00"
                                                                        />
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col xs={6} md={3}>
                                                                <small>Quantity</small>
                                                                <InputGroup className="mb-3">
                                                                    <InputGroup.Text>#</InputGroup.Text>
                                                                    <FormControl
                                                                        type="number"
                                                                        value={task.quantity}
                                                                        onChange={(e) => updateTaskDetails(task.id, 'quantity', e.target.value)}
                                                                        placeholder="0"
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                            </Row>
                                                            
                                                        </Container>
                                                    </InputGroup>
                                                    {/* Display Pomodoro session intervals */}
                                                    <Accordion defaultActiveKey="1" className="py-2">
                                                        <Card>
                                                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                                                Pomodoro Sessions ({taskTimers[task.id]?.length || 0})
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body>
                                                                    <ul>
                                                                        {taskTimers[task.id]?.map((interval, index) => (
                                                                            <li key={index}>
                                                                                {new Date(interval.start).toLocaleString()} - 
                                                                                {new Date(interval.end).toLocaleString()} (
                                                                                {formatElapsedTime(interval.start, interval.end)})
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>

                                                    {/* Display Associated Contacts */}
                                                    <Accordion defaultActiveKey="1" className="py-2">
                                                        <Card>
                                                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                                                Associated Contacts ({task.associatedContacts.length})
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body>
                                                                    <ul>
                                                                        {task.associatedContacts.map(contactId => {
                                                                            const contact = contacts.find(c => c.id === contactId);
                                                                            return (
                                                                                <li key={contactId}>
                                                                                    {contact ? `${contact.firstName} ${contact.lastName}` : 'Unknown Contact'}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>

                                                    {/* Associate Contact Dropdown */}
                                                    <Form.Group>
                                                        <Form.Label>Associate Contact</Form.Label>
                                                        <Form.Control as="select" onChange={e => {
                                                            if (e.target.value) {
                                                                associateTaskWithContact(task.id, e.target.value, () => e.target.value = '');
                                                            }
                                                        }}>
                                                            <option value="">Select a Contact</option>
                                                            {contacts.map(contact => (
                                                                <option key={contact.id} value={contact.id}>
                                                                    {contact.firstName} {contact.lastName}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <small>Changes are saved automatically</small><br />
                                                    {task.completed && <small>Completed at: {task.completedAt}</small>}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    )}
                                </Draggable>
                            ))}
                        {provided.placeholder}
                    </Accordion>
                </div>
            )}
        </Droppable>
    );

    const contactList = (isHot, droppableId) => (
        <Droppable droppableId={droppableId}>
            {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Accordion>
                        {contacts.filter(contact => contact.hot === isHot)
                            .map((contact, index) => (
                                <Draggable key={contact.id} draggableId={contact.id} index={index}>
                                    {(provided) => (
                                        <Card ref={provided.innerRef} {...provided.draggableProps}>
                                            <div {...provided.dragHandleProps} className="drag-handle">
                                                {/* <span className="mr-2 cursor-pointer">☰</span> */}
                                                <span className="mr-2 cursor-pointer"><span style={{ paddingLeft: "0.5em" }}>☰</span> <small style={{ float: "right", paddingRight: "0.5em" }}>Created at: {contact.createdAt}</small></span>
                                            </div>
                                            <Accordion.Toggle as={Card.Header} eventKey={String(contact.id)}>
                                                {contact.firstName} {contact.lastName}
                                                <div className="float-right">
                                                    <Button variant="link" size="sm" onClick={() => toggleContactCategory(contact.id)}>
                                                        {contact.hot ? 'Move to Cold' : 'Move to Hot'}
                                                    </Button>
                                                    <Button variant="link" size="sm" onClick={() => deleteContact(contact.id)}>
                                                        Delete
                                                    </Button>
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={String(contact.id)}>
                                                <Card.Body>
                                                    <InputGroup>
                                                        <Container>
                                                            {/* First Name Field */} {/* Last Name Field */}
                                                            <Row className="py-2">
                                                                <Col xs={12} md={6}>
                                                                    <small>First Name</small>
                                                                    <FormControl
                                                                        type="text"
                                                                        value={contact.firstName}
                                                                        onChange={(e) => updateContactDetails(contact.id, 'firstName', e.target.value)}
                                                                        placeholder="First Name"
                                                                    />
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <small>Last Name</small>
                                                                    <FormControl
                                                                        type="text"
                                                                        value={contact.lastName}
                                                                        onChange={(e) => updateContactDetails(contact.id, 'lastName', e.target.value)}
                                                                        placeholder="Last Name"
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            {/* Company Field */} {/* Title Field */}
                                                            <Row className="py-2">
                                                                <Col xs={12} md={6}>
                                                                    <small>Company</small>
                                                                    <FormControl
                                                                        type="text"
                                                                        value={contact.company}
                                                                        onChange={(e) => updateContactDetails(contact.id, 'company', e.target.value)}
                                                                        placeholder="Company"
                                                                    />
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <small>Title</small>
                                                                    <FormControl
                                                                        type="text"
                                                                        value={contact.title}
                                                                        onChange={(e) => updateContactDetails(contact.id, 'title', e.target.value)}
                                                                        placeholder="Title"
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            {/* Phone Field */} {/* Email Field */}
                                                            <Row className="py-2">
                                                                <Col xs={12} md={6}>
                                                                    <small>Phone</small>
                                                                    <FormControl
                                                                        type="text"
                                                                        value={contact.phone}
                                                                        onChange={(e) => updateContactDetails(contact.id, 'phone', e.target.value)}
                                                                        placeholder="Phone"
                                                                    />
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <small>Email</small>
                                                                    <FormControl
                                                                        type="email"
                                                                        value={contact.email}
                                                                        onChange={(e) => updateContactDetails(contact.id, 'email', e.target.value)}
                                                                        placeholder="Email"
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            {/* Address Field */}
                                                            <Row className="py-2">
                                                                <Col xs={12}>
                                                                    <small>Address</small>
                                                                    <FormControl
                                                                        type="text"
                                                                        value={contact.address}
                                                                        onChange={(e) => updateContactDetails(contact.id, 'address', e.target.value)}
                                                                        placeholder="Address"
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            {/* Date Fields */}
                                                            <Row className="py-2">
                                                                <Col xs={12} md={6}>
                                                                    <small>Date A#1</small>
                                                                    <FormControl
                                                                        type="datetime-local"
                                                                        value={contact.dateA1}
                                                                        onChange={(e) => updateContactDetails(contact.id, 'dateA1', e.target.value)}
                                                                    />
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <small>Date B#2</small>
                                                                    <FormControl
                                                                        type="datetime-local"
                                                                        value={contact.dateB2}
                                                                        onChange={(e) => updateContactDetails(contact.id, 'dateB2', e.target.value)}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            {/* Notes Field */}
                                                            <Row className="py-2">
                                                                <Col xs={12}>
                                                                    <small>Notes</small>
                                                                    <FormControl
                                                                        as="textarea"
                                                                        value={contact.notes}
                                                                        onChange={(e) => updateContactDetails(contact.id, 'notes', e.target.value)}
                                                                        placeholder="Notes"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </InputGroup>

                                                    <Accordion>
                                                        <Card>
                                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                                            Associated Tasks ({contact.associatedTasks.length})
                                                        </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body>
                                                                    {contact.associatedTasks.map(taskId => {
                                                                    const task = tasks.find(t => t.id === taskId);
                                                                    return (
                                                                        <div key={taskId}>
                                                                            <Container>
                                                                                <Row>
                                                                                    <Col xs="8" md="10">
                                                                                        {task ? task.title : 'Unknown Task'}
                                                                                    </Col>
                                                                                    <Col xs="4" md="2">
                                                                                        <Button variant="danger" size="sm" style={{ contentAlign: 'right' }} onClick={() => removeAssociation(taskId, contact.id)}>
                                                                                            Remove
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>    
                                                                            </Container>
                                                                            
                                                                            
                                                                        </div>
                                                                    );
                                                                })}
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                        <small>Changes are saved automatically</small>
                                                    </Accordion>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    )}
                                </Draggable>
                            ))}
                        {provided.placeholder}
                    </Accordion>
                </div>
            )}
        </Droppable>
    );

    const PomodoroTimer = ({ selectedTaskId, onTimerComplete }) => {
        const [timeLeft, setTimeLeft] = useState(25 * 60); // 25 minutes in seconds
        const [timerRunning, setTimerRunning] = useState(false);
        const [intervals, setIntervals] = useState([]);
    
        // Only start the timer if a task is selected
        useEffect(() => {
            if (!selectedTaskId) {
                setTimerRunning(false);
            }
        }, [selectedTaskId]);
        
        useEffect(() => {
            let interval;
            if (timerRunning) {
                interval = setInterval(() => {
                    setTimeLeft(prevTime => {
                        if (prevTime <= 1) {
                            clearInterval(interval);
                            onTimerComplete(selectedTaskId, new Date()); // Use selectedTaskId here
                            return 25 * 60; // Reset timer
                        }
                        return prevTime - 1;
                    });
                }, 1000);
            }
    
            return () => clearInterval(interval);
        }, [timerRunning, selectedTaskId, onTimerComplete]); // Use selectedTaskId here
    
        const startTimer = () => {
            setTimerRunning(true);
            setIntervals([...intervals, { start: new Date() }]); // Record start time
        };
    
        const pauseTimer = () => {
            setTimerRunning(false);
        };
    
        const finishTimer = () => {
            setTimerRunning(false);
            if (intervals.length > 0) {
                let lastInterval = intervals[intervals.length - 1];
                lastInterval.end = new Date(); // Record end time
        
                // Update the taskTimers state with the new interval
                const updatedTimers = { ...taskTimers };
                if (!updatedTimers[selectedTaskId]) {
                    updatedTimers[selectedTaskId] = [];
                }
                updatedTimers[selectedTaskId].push(lastInterval);
                setTaskTimers(updatedTimers);
        
                // Update the tasks state with the new interval
                const updatedTasks = tasks.map(task => {
                    if (task.id === selectedTaskId) {
                        return { ...task, pomodoroSessions: [...(task.pomodoroSessions || []), lastInterval] };
                    }
                    return task;
                });
                setTasks(updatedTasks);
                saveTasksToLocalStorage(updatedTasks);
            }
            setIntervals([]); // Reset intervals
        };
    
        const displayTime = `${String(Math.floor(timeLeft / 60)).padStart(2, '0')}:${String(timeLeft % 60).padStart(2, '0')}`;
    
        return (
            <Row>
                <Col xs="3" md="6">
                    <div className="timer-display">{displayTime}</div>
                </Col>
                <Col xs="3" md="2">
                    <Button onClick={startTimer} disabled={timerRunning} className="px-3 shadow">Start</Button>
                </Col>
                <Col xs="3" md="2">
                    <Button onClick={pauseTimer} disabled={!timerRunning} className="px-3 shadow">Pause</Button>
                </Col>
                <Col xs="3" md="2">
                    <Button onClick={finishTimer} disabled={timerRunning || intervals.length === 0} className="px-3 shadow">Finish</Button>
                </Col>
            </Row>
        );
    };

    // Pomodoro Timer Display
    const PomodoroTimerDisplay = () => (
        <Row>
            <Form.Label className="text-center" ><h1>Pomodoro Timer</h1></Form.Label>
            <Col md={6}>
                <Form.Group>
                    <Form.Control as="select" value={selectedTaskForTimer} onChange={handleSelectTaskForTimer} className="shadow">
                        <option value="">Select a Task</option>
                        {tasks.map(task => (
                            <option key={task.id} value={task.id}>{task.title}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col md={6}>
                <PomodoroTimer selectedTaskId={selectedTaskForTimer} onTimerComplete={handleTimerComplete} />
            </Col>
        </Row>
    );

    return (
        <>
            <Container fluid className="bg-success bg-muted bg-gradient shadow">
                <Container className="py-3">            
                    <Row className="Xjustify-content-md-center py-2">
                        <Col xs={12} md={12}>
                            <Card className="shadow">
                                <Card.Header className="text-center">
                                    <h1 className="text-success">{listName ? listName : "Task Manager"}</h1>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={addTask}>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                type="text"
                                                value={newTask}
                                                onChange={(e) => setNewTask(e.target.value)}
                                                placeholder="Enter a new task"
                                            />
                                            <InputGroup.Append>
                                                <Button variant="primary" type="submit" className="padding-unset px-2">Add Task</Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col xs={6} md={4} className="py-2">
                                            <h2 className="d-none">Your Tasks QR Code</h2>
                                            <Button variant="primary" onClick={() => setShowQRCodeModal(true)} className="btn btn-primary shadow w-100 py-2">Show QR Code</Button>
                                        </Col>
                                        <Col xs={6} md={4} className="py-2">
                                            <h2 className="d-none">Scan Tasks QR Code</h2>
                                            <Button variant="primary" onClick={() => setShowScanner(true)} className="btn btn-primary shadow w-100 py-2">Scan QR Code</Button>
                                        </Col>
                                        <Col xs={12} md={4} className="py-2">
                                            <h2 className="d-none">Edit List Name</h2>
                                            <Button variant="primary" onClick={() => setShowListNameModal(true)} className="btn btn-primary shadow w-100 py-2">Edit List Name</Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>                    
                    </Row>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Row className="Xjustify-content-md-center">
                            <Col xs={12} md={6} className="py-2">
                                <Card className="shadow">
                                    <Card.Header className="text-center"><h3>Active Tasks</h3></Card.Header>
                                    <Card.Body>{taskList(false, "activeTasks")}</Card.Body>
                                    <Card.Footer className="text-center"><small>drag to reorder or complete</small></Card.Footer>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="py-2">                       
                                <Card className="shadow">
                                    <Card.Header className="text-center"><h3>Completed Tasks</h3></Card.Header>
                                    <Card.Body>{taskList(true, "completedTasks")}</Card.Body>
                                    <Card.Footer className="text-center"><small>drag to reorder or reactivate</small></Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </DragDropContext>

                    

                    {/* Modal for QR Code Scanner */}
                    <Modal show={showScanner} onHide={() => setShowScanner(false)} centered size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Scan QR Code</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <QrReader
                                delay={300}
                                videoId={selectedCameraId}
                                onResult={handleResult}
                                style={{ height: '100%', width: '100%' }}
                            />
                            {videoDevices.length > 1 && (
                                <Button onClick={switchCamera}>Switch Camera</Button>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowScanner(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for Import Confirmation */}
                    {scanResult && (
                        <Modal show={true} onHide={() => setScanResult(null)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Import Tasks</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Do you want to import scanned tasks?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setScanResult(null)}>Cancel</Button>
                                <Button variant="primary" onClick={importTasks}>Import</Button>
                            </Modal.Footer>
                        </Modal>
                    )}

                    {/* Modal for QR Code Display */}
                    <Modal show={showQRCodeModal} onHide={() => setShowQRCodeModal(false)} centered size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Tasks QR Code</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>List Name: {listName}</p>
                            <QRCode
                                value={encodedTasks()}
                                size={512} // Increase the size for higher resolution
                                level={"H"} // Higher error correction level
                                style={{ minHeight: '320px', minWidth: 'auto', maxHeight: '85vw', maxWidth: '85vh', aspectRatio: '1 / 1' }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowQRCodeModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for Editing List Name */}
                    <Modal show={showListNameModal} onHide={() => setShowListNameModal(false)} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit List Name</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>List Name</Form.Label>
                                <Form.Control type="text" value={listName} onChange={handleListNameChange} maxLength="64" />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={clearLocalStorage}>Force Clear Local Storage</Button>
                            <Button variant="secondary" onClick={() => setShowListNameModal(false)}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    
                </Container>

            </Container>

            <Container fluid className="bg-warning bg-muted bg-gradient py-5 shadow">
                <Container>
                    <PomodoroTimerDisplay />
                </Container>
            </Container>

            <Container fluid className="bg-info bg-muted bg-gradient shadow">
                <Container className="py-3">

                    <Container >
                            <Row className="Xjustify-content-md-center py-2">
                                <Col xs={12} md={12} className="px-0">
                                    <Card className="shadow">
                                        <Card.Header className="text-center">
                                            <h1 className="text-info">Contacts List</h1>
                                        </Card.Header>
                                        <Card.Body>
                                        <Form onSubmit={addContact}>
                                            <Row className="justify-content-md-center py-2">
                                                <Col xs={6} md={3}>
                                                    <Form.Group>
                                                        <Form.Control
                                                            type="text"
                                                            value={newContactFirstName}
                                                            onChange={(e) => setNewContactFirstName(e.target.value)}
                                                            placeholder="First Name"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} md={3}>
                                                    <Form.Group>
                                                        <Form.Control
                                                            type="text"
                                                            value={newContactLastName}
                                                            onChange={(e) => setNewContactLastName(e.target.value)}
                                                            placeholder="Last Name"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group>
                                                        <FormControl
                                                            type="text"
                                                            value={newContactCompany}
                                                            onChange={(e) => setNewContactCompany(e.target.value)}
                                                            placeholder="Company"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Button variant="primary" type="submit" className="btn btn-primary shadow-sm w-100 padding-unset py-2">Add Contact</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                        </Card.Body>
                                        <Card.Footer className="text-center">
                                            <Row className="d-none">
                                                <Col xs={6} md={4}>
                                                    <h2 className="d-none">Your Tasks QR Code</h2>
                                                    <Button variant="primary" onClick={() => setShowQRCodeModal(true)} className="btn btn-primary shadow-sm w-100 py-2">Show QR Code</Button>
                                                </Col>
                                                <Col xs={6} md={4}>
                                                    <h2 className="d-none">Scan Tasks QR Code</h2>
                                                    <Button variant="primary" onClick={() => setShowScanner(true)} className="btn btn-primary shadow-sm w-100 py-2">Scan QR Code</Button>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <h2 className="d-none">Edit List Name</h2>
                                                    <Button variant="primary" onClick={() => setShowListNameModal(true)} className="btn btn-primary shadow-sm w-100 py-2">Edit List Name</Button>
                                                </Col>
                                            </Row>
                                            <small>Start simple, then add more information below.</small>
                                        </Card.Footer>
                                    </Card>
                                </Col>                    
                            </Row>
                        </Container>
                        

                        <DragDropContext onDragEnd={onDragEnd}>
                            {/* Contacts Section */}
                            <Row className="">
                                <Col xs={12} md={6} className="py-2">
                                    <Card className="shadow">
                                        <Card.Header className="text-center"><h3>Hot Contacts</h3></Card.Header>
                                        <Card.Body>{contactList(true, "hotContacts")}</Card.Body>
                                        <Card.Footer className="text-center"><small>Drag to reorder or move to Cold</small></Card.Footer>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} className="py-2">
                                    <Card className="shadow">
                                        <Card.Header className="text-center"><h3>Cold Contacts</h3></Card.Header>
                                        <Card.Body>{contactList(false, "coldContacts")}</Card.Body>
                                        <Card.Footer className="text-center"><small>Drag to reorder or move to Hot</small></Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        </DragDropContext>
                        
                </Container>
            </Container>
        </>
    );
};

export default App;