// Standalone NotFoundComponent.js for 404 Pages
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row, Container } from 'react-bootstrap';

const NotFoundComponent = () => {
    const history = useHistory();
    const [fadeEffect, setFadeEffect] = useState(false);

    const handleGoBack = () => {
        history.goBack();
    };

    const handleGoHome = () => {
        history.push('/');
    };

    const handleGetLucky = () => {
        setFadeEffect(true);
        const randomPath = ['splat', 'potato', 'retro'][Math.floor(Math.random() * 3)];
    
        setTimeout(() => {
          history.push(`/${randomPath}`);
        }, 1000); // Adjust timing to match the duration of your fade effect
      };

  return (
    <Container fluid className="text-center py-5 herobgfaded" style={{ minHeight: '50vh', display: 'block', overflow: 'auto'}}>
      <Row className="my-5 py-5">
        <Col className="col-4 my-5 py-5 text-dark">&nbsp;</Col>
        <Col className="col-4 my-5 py-5 text-dark">
          <h1>404 - Page Not Found</h1>
          <h5>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</h5>
          <Button onClick={handleGoBack}>Go Back</Button>
          <Button onClick={handleGoHome} className="mx-2">Go Home</Button>
          <Button className="d-none" onClick={handleGetLucky}>Get Lucky</Button>
        </Col>
        <Col className="col-4 my-5 py-5 text-dark">&nbsp;</Col>
      </Row>
    </Container>
  );
};

export default NotFoundComponent;
