import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';

const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const contactRef = firebase.database().ref('contacts');
        const contact = {
            name,
            email,
            message,
            timestamp: firebase.database.ServerValue.TIMESTAMP
        };

        contactRef.push(contact)
            .then(() => {
                setName('');
                setEmail('');
                setMessage('');
                setSubmitted(true);
                setTimeout(() => setSubmitted(false), 5000);
            })
            .catch(error => console.error("Error saving contact:", error));
    };

    return (
        <Container className="my-5">
            <Row>
                <Col md={12}>
                    <h2 className="text-center text-info">Contact Us</h2>
                    <Form className="bg-success text-dark rounded px-3 py-3 shadow" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button variant="info" type="submit" className="shadow">
                            Submit
                        </Button>
                        {submitted && <Alert variant="success" className="mt-3 shadow">Thank you for contacting us!</Alert>}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default ContactUs;
