import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => (
    <footer className="footer bg-light text-dark py-4 border-bottom-0 border-left-0 border-right-0 border-success border-top border-5">
        <Container>
            <Row>
                <Col md={4}>
                    <h5>About Would You Kindly</h5>
                    <p>Explore streamlined task and contact management! Our platform blends task organization, contact management, and productivity tools for an efficient, secure, and user-friendly experience. Perfect for personal productivity and team collaboration.</p>
                </Col>
                <Col md={2} className="col-6">
                    <h5>Quick Links</h5>
                    <ul className="list-unstyled">
                        <li><a href="/jargon#jargon-about" className="text-dark" style={{ textDecoration: "none" }}>About Us</a></li>
                        <li><a href="/jargon#jargon-contact" className="text-dark" style={{ textDecoration: "none" }}>Contact Us</a></li>
                        <li><a href="/jargon#jargon-terms" className="text-dark" style={{ textDecoration: "none" }}>Terms of Service</a></li>
                        <li><a href="/jargon#jargon-privacy" className="text-dark" style={{ textDecoration: "none" }}>Privacy Policy</a></li>
                    </ul>
                </Col>
                <Col md={2} className="col-6">
                    <h5>By <a href="https://redradar.dev" className="text-danger" style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">RedRadar</a></h5>
                    <ul className="list-unstyled">
                        <li><a href="https://artmash.io" className="text-dark" style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">ArtMash</a></li>
                        <li><a href="https://partypics.zip" className="text-dark" style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">PartyPics</a></li>
                        <li><a href="https://www.chaturfake.com" className="text-dark" style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">ChatUrFake</a></li>
                        <li><a href="https://www.cutoutcamera.com" className="text-dark" style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">Cut-Out Camera</a></li>
                    </ul>
                </Col>
                <Col md={4}>
                    <h5>Contact Info</h5>
                    <p>Email: support@wyk.app <br />
                        Phone: +123 456 7890 <br />
                        Address: 123 Kindly St., Woodewe City, WY 12345</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-center mt-3">
                    <p>&copy; {new Date().getFullYear()} Would You Kindly. All rights reserved.</p>
                </Col>
            </Row>
        </Container>
    </footer>
);

export default Footer;