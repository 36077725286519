// Privacy Policy Component
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = () => (
    <Container className="my-5">
        <Row>
            <Col md={12}>
                <h2 className="text-center text-info">Privacy Policy</h2>
                <p className="text-dark">
                    Your privacy is of utmost importance to us. The Task and Contact Management Application is dedicated to protecting your personal information and ensuring your data is secure. Our privacy policy details how we handle and protect the data collected through our application.
                </p>
                <h4 className="text-dark">1. Data Collection</h4>
                <p className="text-dark">
                    We collect information necessary for providing our services, including task details and contact information. All data is encrypted for your security.
                </p>
                <h4 className="text-dark">2. Use of Data</h4>
                <p className="text-dark">
                    The information collected is used to improve service functionality, such as task management and contact categorization.
                </p>
                <h4 className="text-dark">3. Data Sharing</h4>
                <p className="text-dark">
                    We do not share your personal data with third parties, except as required for legal compliance or to provide the service (e.g., data backup).
                </p>
                <h4 className="text-dark">4. Data Security</h4>
                <p className="text-dark">
                    We implement various security measures to protect your data, including secure data storage and encryption technologies.
                </p>
                <h4 className="text-dark">5. Changes to This Policy</h4>
                <p className="text-dark">
                    We may update our Privacy Policy from time to time. Users will be notified of any significant changes that affect the handling of personal data.
                </p>
            </Col>
        </Row>
    </Container>
);

export default PrivacyPolicy;
