import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const FAQComponent = () => {
    return (
        <Container>
            <Row className="justify-content-center my-4">
                <h1 className="text-center">How To Use <span className="text-success">Would</span> <span className="text-warning">You</span> <span className="text-info">Kindly</span></h1>
            </Row>
            <Row className="align-self-stretch my-3 py-3">
                {/* Column 1 */}
                <Col xs={12} md={4} >
                    <Card className="mb-4">
                        <Card.Header className="bg-success text-light" >Getting Started</Card.Header>
                        <Card.Body>
                            <p>Launch the application and set a name for your task list. Begin your journey towards organized productivity.</p>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={4} >
                    <Card className="mb-4">
                        <Card.Header className="bg-info text-light">Task Management</Card.Header>
                        <Card.Body>
                            <p>Create tasks, manage completion status, and organize them. Your data is securely stored and encrypted.</p>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Column 2 */}
                <Col xs={12} md={4}>
                    <Card className="mb-4">
                        <Card.Header className="bg-success text-light">Contact Management</Card.Header>
                        <Card.Body>
                            <p>Add, categorize, and manage contacts. Store detailed information and link them to tasks.</p>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            <Row className="align-self-stretch my-3 py-3">

                <Col xs={12} md={4} >
                    <Card className="mb-4">
                        <Card.Header className="bg-info text-light">Pomodoro Timer</Card.Header>
                        <Card.Body>
                            <p>Use the Pomodoro timer for enhanced focus and productivity. Track your work sessions efficiently.</p>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Column 3 */}
                <Col xs={12} md={4}>
                    <Card className="mb-4">
                        <Card.Header className="bg-success text-light">QR Code Functionality</Card.Header>
                        <Card.Body>
                            <p>Share and import tasks easily using QR codes. A seamless way to manage task sharing.</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} >
                    <Card className="mb-4">
                        <Card.Header className="bg-info text-light">Personalize Your Experience</Card.Header>
                        <Card.Body>
                            <p>Customize your task list's name and feel secure with local storage of your data.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="d-none">
                <Col>
                    <Card className="mt-4">
                        <Card.Header className="bg-warning text-dark">FAQ</Card.Header>
                        <Card.Body>
                            <ul>
                                <li>Add tasks easily with the 'Add Task' button.</li>
                                <li>Edit tasks by clicking on them.</li>
                                <li>Use the Pomodoro timer to enhance focus.</li>
                                <li>Categorize contacts for better organization.</li>
                                <li>Share tasks using QR codes for convenience.</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default FAQComponent;
