// Terms of Service Component
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const TermsOfService = () => (
    <Container className="my-5">
        <Row>
            <Col md={12}>
                <h2 className="text-center text-info">Terms of Service</h2>
                <p className="text-dark">
                    By using the Task and Contact Management Application, you agree to our terms, designed to ensure a smooth and secure experience for all users. Please read these terms carefully as they outline your rights and responsibilities.
                </p>
                <h4 className="text-dark">1. Acceptable Use</h4>
                <p className="text-dark">
                    You must use our services for lawful purposes and adhere to all guidelines provided within the application. This includes respecting privacy and intellectual property rights.
                </p>
                <h4 className="text-dark">2. Data Security</h4>
                <p className="text-dark">
                    Your data is encrypted and stored securely. We prioritize protecting your personal information but remind you to be cautious when sharing sensitive data.
                </p>
                <h4 className="text-dark">3. Modifications to Service</h4>
                <p className="text-dark">
                    We may modify or discontinue any feature or functionality of the application at any time, without prior notice, to enhance the service.
                </p>
                <h4 className="text-dark">4. Limitation of Liability</h4>
                <p className="text-dark">
                    Our application is provided as-is. We are not liable for any direct or indirect damages resulting from the use or inability to use our service.
                </p>
                <h4 className="text-dark">5. User Responsibilities</h4>
                <p className="text-dark">
                    Users are responsible for managing their tasks and contacts within the application. We are not liable for any loss of data due to user error.
                </p>
            </Col>
        </Row>
    </Container>
);

export default TermsOfService;