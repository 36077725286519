// About Us Component
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import About from "./AboutNew"
import Contact from "./Contact"
import Terms from "./Terms"
import Privacy from "./Privacy"

const Jargon = () => (
    <Container className="my-5">
        <Row>
            <Col md={6} id="jargon-about">
                <About />
            </Col>
            <Col md={6} id="jargon-contact">
                <Contact />
            </Col>
        </Row>
        <Row>
            <Col md={6} id="jargon-terms">
                <Terms />
            </Col>
            <Col md={6} id="jargon-privacy">
                <Privacy />
            </Col>
        </Row>
    </Container>
);

export default Jargon;